import * as React from "react"
import { ChangeEvent, FormEvent } from "react"
import { SEO } from "../components/seo"
import { graphql, Link, navigate } from "gatsby"
import {
  QuizResults,
  Quizzes_allQuizzesJson_edges_node_node,
  Quizzes_allQuizzesJson_edges_node_node_relatesTo,
  Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node,
} from "../types/api"
import { SecondaryHeader } from "../components/header"
import { Footer } from "../components/Footer"
import GatsbyImage from "gatsby-image"
import { Ad, position, VendorInit } from "../components/ad"
import * as Trivia from "../model/Trivia"
import { QuizStatus } from "../model/Trivia"
import { Omit } from "../model/types"
import * as TriviaStorage from "../model/TriviaStorage"
import { selectLayoutVariant, getLayout, clearSnappy } from "../model/helpers"
import CheckCircle from "../images/svg/check-circle.svg"
import LightBulb from "../images/svg/light-bulb.svg"
import PlayCircle from "../images/svg/play-circle.svg"
import TrendingUp from "../images/svg/trending-up.svg"
import XCircle from "../images/svg/x-circle.svg"
import Facebook from "../images/svg/facebook.svg"
import ChevronDown from "../images/svg/chevron-down.svg"
import { BlockConfetti } from "../components/Conffeti"

const bg = require("../images/bg.jpg")

type Props = {
  pageContext: {
    id: string
    slug: string
    page: number
    totalPages: number
    quiz: Omit<Omit<Quizzes_allQuizzesJson_edges_node_node, "questionSet">, "relatesTo">
    relatedQuizzes?: Quizzes_allQuizzesJson_edges_node_node_relatesTo
  }
  location: any
  data: QuizResults
}

type State = {
  preloadNext: boolean
  status: QuizStatus
  wasPreviousCorrect: boolean
  email: string
  consent: boolean
  adsLayout: Layouts_allLayoutsJson_edges_node_node_variantSet_edges_node
  text: string
}

declare global {
  interface Window {
    dataLayer: Array<object>
  }
}

export default class extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      preloadNext: false,
      status: null,
      wasPreviousCorrect: false,
      email: "",
      consent: true,
      adsLayout: null,
      text: "your results!",
    }
  }

  componentDidMount() {
    if (document && document.referrer && document.referrer.includes("/page/2")) {
      this.setState({ text: "the answer!" })
    }
    clearSnappy(true)
    const status = Trivia.getStatus(this.slug)
    const wasPreviousCorrect = Trivia.wasAnswerCorrect(this.slug, this.page - 1)
    this.setState({
      status,
      wasPreviousCorrect,
    })

    if (this.state.adsLayout === null) {
      getLayout().then(response =>
        this.setState({ adsLayout: selectLayoutVariant(response, this.props.pageContext.page + 2) })
      )
    }
  }

  render() {
    TriviaStorage.saveUtmFromLocation(this.props.location)
    let metaDescription = "Quiz: " + this.quiz.title
    if (this.quiz.description) {
      metaDescription = this.quiz.description.substring(0, 290) + " ..."
    }

    let metaImg = null
    if (this.props.data.featuredImage && this.props.data.featuredImage.childImageSharp) {
      metaImg = this.props.data.featuredImage.childImageSharp.fluid
    }

    let url = this.isShowResultsPage ? "/entry" : "/score"

    return (
      <>
        <SEO
          title={`Quiz: ${this.quiz.title}`}
          isQuiz={true}
          description={metaDescription}
          url={`${this.props.pageContext.slug}${url}`}
          img={metaImg}
        />
        <VendorInit position="vendorInit" layout={this.state.adsLayout} resultPage={true} />
        <SecondaryHeader />
        <div className="answer-modal hide-it" id="answer-modal" />
        <div id="page-level-tests" />
        <div className="game-parent">
          <div className="container">
            {this.isShowResultsPage ? this.renderShowResults() : null}
            {this.isAfterResultsPage ? this.renderAfterResultsPage() : null}
          </div>
        </div>
        <Footer slug={this.slug} />
      </>
    )
  }

  private renderAfterResultsPage() {
    const congrats = require("../images/congrats.png")
    const overlay = require("../images/overlay-2.jpg")
    const stamp = require("../images/stamp.png")
    const chevronLeft = require("../images/chevron-left.png")
    const chevronRight = require("../images/chevron-right.png")
    const play = require("../images/play.png")
    if (!this.state.status) {
      return null
    }

    const confettiJsOptions = {
      max: 280,
      size: 1,
      animate: true,
      props: ["circle", "square", "triangle", "line"],
      colors: [[203, 153, 35], [203, 188, 97], [204, 214, 187], [138, 111, 56]],
      clock: 20,
      rotate: false,
      start_from_edge: false,
      respawn: true,
      width: 914,
      height: 350,
    }

    return (
      <div className="row">
        <div className="game-main-body summary">
          <div className="summary-head" style={{ backgroundImage: "url(" + overlay + ")" }}>
            <div className="summary-head-bg">
              <BlockConfetti options={confettiJsOptions} className="relative overflow-hidden">
                <div className="summary-head-wrapper">
                  <img src={congrats} className="summary-congrats" />
                  <br />
                  <span className="summary-head-text">You answered {this.state.status.prettyScore} correctly!</span>
                </div>
              </BlockConfetti>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="summary-stamp" style={{ backgroundImage: "url(" + stamp + ")" }}>
                  <div className="summary-small-text">You are a:</div>
                  <div className="summary-rank">{this.state.status.rank}</div>
                </div>
                <div className="summary-table">
                  <div className="summary-table-block summary-table-block-b">
                    <div>You Have</div>
                    <div className="summary-table-block-green">
                      <CheckCircle />
                      {this.state.status.correctAnswers} right answers
                    </div>
                  </div>
                  <div className="summary-table-block summary-table-block-b">
                    <div>You Have</div>
                    <div className="summary-table-block-red">
                      <XCircle />
                      {this.state.status.wrongAnswers} WRONG answers
                    </div>
                  </div>
                  <div className="summary-table-block">
                    <div>Your Longest Streak</div>
                    <div className="summary-table-block-green">
                      <TrendingUp />
                      {this.state.status.streakMax} Right in a row
                    </div>
                  </div>
                </div>
                <div className="summary-small-text">Challenge Your Friends on Facebook!</div>
                <div
                  className="fb-button-wrapper"
                  data-href={`https://triviaboss.com/${this.props.pageContext.slug}/?v=1`}
                  data-layout="button"
                  data-size="small"
                  data-mobile-iframe="true"
                >
                  <div
                    className="chevron"
                    style={{ backgroundImage: "url(" + chevronRight + ")", backgroundPosition: "left" }}
                  ></div>
                  <a
                    target="_blank"
                    href={`https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Ftriviaboss.com%2F${this.props.pageContext.slug}%2F%3Fv%3D1%26utm_campaign%3Dscore%26utm_source%3Dfb&src%3Dsdkpreparse&quote=I got ${this.state.status.prettyScore} right. That is better than ${this.state.status.prettyBetterThan} of people. Let's see how you do!`}
                    className="fb-button-a"
                  >
                    <div className="fb-button">
                      <div className="fb-logo">
                        <Facebook />
                      </div>
                      <div className="fb-text">Share On Facebook</div>
                    </div>
                  </a>
                  <div
                    className="chevron"
                    style={{ backgroundImage: "url(" + chevronLeft + ")", backgroundPosition: "right" }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12">
                <div className="summary-small-text summary-lightbulb">
                  <LightBulb />
                  You are smarter than
                </div>
                <div className="summary-better-than">{this.state.status.prettyBetterThan}</div>
                <div className="summary-small-text">of your peers!</div>
                <div className="chevron-down">
                  <ChevronDown />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xs-12 wrap-up-you-might-like">
                <PlayCircle />
                <div>
                  Keep the Party Going. <span> Try Another Quiz!</span>
                </div>
              </div>
            </div>

            {this.props.pageContext.relatedQuizzes.edges.map(({ node }) => {
              const image = this.relatedQuizzesImages.find(n => n.relativePath === node.featuredImage)
              return (
                <div key={node.id}>
                  {image ? (
                    <div className="row">
                      <div className="col-md-12">
                        <div>
                          <GatsbyImage fluid={image.childImageSharp.fluid} className="summary-next-quiz" />
                          <div className="summary-next-quiz-headline-wrapper">
                            <div className="summary-next-quiz-headline">
                              <span className="next-quiz-title">{node.title}</span>
                              <a
                                href={process.env.GATSBY_URL_PREFIX + `/${node.slug}/`}
                                className="btn btn-primary start-quiz-button"
                              >
                                <div className="start-quiz-button-play">
                                  <div style={{ backgroundImage: "url(" + play + ")" }}></div>
                                </div>
                                <span>Start</span>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              )
            })}
          </div>
        </div>
        {this.renderRightRail()}
      </div>
    )
  }

  private renderShowResults() {
    return (
      <div id="entry-page" style={{ backgroundImage: "url(" + bg + ")" }}>
        <div className="wrapper">
          <h3>
            <span>Congratulations!</span>
          </h3>
          <p className="under-title">Enter your email to view {this.state.text}</p>
          <p className="under-title-2">
            Join 2.2 million people and test your knowledge with Trivia Boss! <br />
          </p>
          <form onSubmit={this.handleShowResults}>
            <div className="form-wrapper" id="entry-page-unit-form-wrapper">
              <div className="email-wrapper">
                <input
                  type="email"
                  name="house_unit_email"
                  placeholder="Enter Email"
                  id="entry-page-unit-email"
                  required
                  onChange={this.onEmailChange}
                  value={this.state.email}
                />
                <div className="input-error" id="entry-page-unit-error"></div>
              </div>
              <input id="entry-page-unit-submit" type="submit" value="See Results" />
            </div>
            <div>
              <input type="checkbox" name="consent" checked={this.state.consent} onChange={this.onConsentChange} />
              Yes! I'd like to receive emails from TriviaBoss.com including chances to win and offers. I know I can
              unsubscribe at any time. <a href="/privacy/">Privacy Policy</a>.
            </div>
          </form>
          <p className="footer"></p>
        </div>
      </div>
    )
  }

  private renderRightRail = () => {
    return (
      <div className="game-rail sticky-sidebar right-rail" id="right-rail">
        <span className="game-right-rail-ad-label">Advertisements</span>
        <div id="RIGHT_RAIL_UPPER">
          <Ad position={position.RIGHT_RAIL_UPPER} layout={this.state.adsLayout} />
        </div>
        <div id="RIGHT_RAIL_MIDDLE">
          <Ad position={position.RIGHT_RAIL_MIDDLE} layout={this.state.adsLayout} />
        </div>
        <div id="RIGHT_RAIL_LOWER">
          <Ad position={position.RIGHT_RAIL_LOWER} layout={this.state.adsLayout} />
        </div>
      </div>
    )
  }

  private get slug() {
    return this.props.pageContext.slug
  }

  private get page() {
    return this.props.pageContext.page
  }

  private get totalPages() {
    return this.props.pageContext.totalPages
  }

  private get quiz() {
    return this.props.pageContext.quiz
  }

  private get isShowResultsPage() {
    return this.page == this.totalPages
  }

  private get isAfterResultsPage() {
    return this.page - 1 == this.totalPages
  }

  private get relatedQuizzesImages() {
    return this.props.data.relatedQuizzesImages.edges.map(e => e.node)
  }

  private handleShowResults = (e: FormEvent) => {
    e.preventDefault()

    if (this.state.email.trim() && this.state.consent) {
      require("es6-promise").polyfill()
      require("isomorphic-fetch")
      fetch(process.env.GATSBY_API_URL + "sweeps/" + process.env.GATSBY_API_TOKEN, {
        method: "POST",
        body: JSON.stringify({
          email: this.state.email,
          quiz: this.quiz.title,
          gsv_status: this.state.consent ? "opted_in" : "opted_out",
          datetime: Date.now(),
          utm_source: TriviaStorage.getUtmSource(),
          utm_medium: TriviaStorage.getUtmMedium(),
          utm_campaign: TriviaStorage.getUtmCampaign(),
          utm_term: TriviaStorage.getUtmTerm(),
          utm_content: TriviaStorage.getUtmContent(),
        }),
        headers: { "Content-Type": "application/json" },
      })
        .then(res => res.json())
        .then(response => {
          console.log("Success:", JSON.stringify(response))
          window.dataLayer = window.dataLayer || []
          TriviaStorage.setEntryConversion(true)
          window.dataLayer.push({ event: "entry_conversion" })
        })
        .catch(error => console.error("Error:", error))
    } else {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({ event: "nonentry_conversion" })
    }
    if (TriviaStorage.getEp() != null && TriviaStorage.getEp() < this.page) {
      navigate(`/${this.props.pageContext.slug}/page/${TriviaStorage.getEp() + 1}/`)
    } else {
      navigate(`/${this.props.pageContext.slug}/score/`)
    }
  }
  private onEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ email: e.target.value })
  }
  private onConsentChange = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState({ consent: e.target.checked })
  }
}

export const query = graphql`
  query QuizResults($featuredImage: String, $relatedQuizzesImages: [String], $hasRelatedQuizzesImages: Boolean!) {
    featuredImage: file(relativePath: { eq: $featuredImage }) {
      relativePath
      name
      childImageSharp {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
    relatedQuizzesImages: allFile(filter: { relativePath: { in: $relatedQuizzesImages } })
      @include(if: $hasRelatedQuizzesImages) {
      edges {
        node {
          relativePath
          name
          childImageSharp {
            fluid {
              base64
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
      }
    }
  }
`
