import React, { useEffect } from "react"
import ConfettiGenerator from "confetti-js"

type Props = {
  children?: any
  options?: any
  className?: string
}

export const BlockConfetti: React.FC<Props> = ({ children, options, className }) => {
  /** init ConfettiGenerator*/
  useEffect(() => {
    const confetti = new ConfettiGenerator({
      target: "confetti-canvas",
      ...options,
    })
    confetti.render()

    return () => confetti.clear()
  }, [])

  return (
    <div className={className}>
      <canvas id="confetti-canvas" className="confetti-canvas" />
      {children && <div className="relative overflow-hidden">{children}</div>}
    </div>
  )
}
